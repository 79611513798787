<template>
    <div class="container">
        <div class="banner">
            <swiper ref="swiper"/>
        </div>
        <div class="community">
            <div class="navtitle">
                <div class="title">年轻人的潮流社区</div>
                <div class="horizontal_line"></div>
            </div>
            <div class="logo_out">
                <div class="left_btn" @click="changeSlide('left')">
                    <img src="@/assets/image/zh.png">
                </div>
                <div class="logo_list" ref="logoList">
                    <div class="list_item" ref="listItem" v-for="(item,index) in logoList" :key="index" :class="item.active?'active':''" @click="changeLogo(index)" :style="{ transform: `translateX(${translateX}px)` }">
                        <img :src="item.icon">
                    </div>
                </div>
                <div class="right_btn" @click="changeSlide('right')">
                    <img src="@/assets/image/yh.png">
                </div>
            </div>
            <div class="brand_list">
                <img v-lazy="barndList[logCurrent]?.details?.images[0]?.src" class="bigimg">
                <div class="subtitle">{{ barndList[logCurrent]?.title }}</div>
                <p>{{ barndList[logCurrent]?.intro }}</p>
                <template v-if="!logCurrent">
                    <div class="area">
                        <span  v-for="(item, index) in barndList[current]?.details?.cities" :key="index">
                            {{ handleArea(item) }}
                        </span>
                    </div>
                    <div>其他一二线城市持续扩展中</div>
                    <div class="brand_footer">
                        <div class="brand_footer_item">
                            <img src="../../../assets/image/pcs.png">
                            <div class="item_right">
                                <span class="count">{{ barndList[current]?.details?.cities?.length }}</span>
                                <span>大入驻一线及二线城市</span>
                            </div>
                        </div>
                        <div class="brand_footer_item">
                            <img src="../../../assets/image/pmd.png">
                            <div class="item_right">
                                <span class="count">{{ barndList[current]?.details?.store_num }}+</span>
                                <span>运营门店</span>
                            </div>
                        </div>
                        <div class="brand_footer_item">
                            <img src="../../../assets/image/pfy.png">
                            <div class="item_right">
                                <span class="count">{{ barndList[current]?.details?.user_num }}+</span>
                                <span>城市青年用户</span>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <div class="life">
            <div class="navtitle">
                <div class="title">品质，时尚的生活环境</div>
                <div class="horizontal_line" style="background: #fff"></div>
            </div>
            <div class="btn_list">
                <div class="btn" :class="item.active ? 'active' : ''" v-for="(item, index) in houseTypeList" :key="index"
                    @click="changTabs(index, 'houseTypeList')">
                    {{ item.layout_group }}
                </div>
            </div>
            <div class="room_list">
                <div class="room_list_item" v-for="(item, index) in houseTypeList[getHouseTypeIndex]?.datalist" :key="index" @click="toHouseDetail(item.fx_id)">
                    <img v-lazy="item.cover_picture?.path">
                    <div class="item_f">
                        <span class="model">{{ houseTypeList[getHouseTypeIndex]?.layout_group }}</span>
                        <span class="introduce">{{ item.fx_name }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="for_us">
            <div class="navtitle">
                <div class="title" style="color: #fff;">选择我们的理由</div>
                <div class="horizontal_line" style="background-color: rgba(255, 255, 255, 0.3);"></div>
            </div>
            <div class="us_list">
                <div class="us_list_item">
                    <img src="@/assets/image/pz.png" />
                    <span>实惠品质好房</span>
                </div>
                <div class="us_list_item">
                    <img src="@/assets/image/lszz.png" />
                    <span>品牌连锁保障</span>
                </div>
                <div class="us_list_item">
                    <img src="@/assets/image/gjfw.png" />
                    <span>专业管家服务</span>
                </div>
                <div class="us_list_item">
                    <img src="@/assets/image/yzs.png" />
                    <span>一站式省心</span>
                </div>
            </div>
        </div>
        <div class="news">
            <div class="navtitle">
                <div class="title">新闻资讯</div>
                <div class="horizontal_line"></div>
            </div>
            <div class="btn_list">
                <div class="btn" :class="item.active ? 'active' : ''" v-for="(item, index) in articlesList" :key="index" @click="changTabs(index, 'articlesList')">
                    {{ item.category_group }}
                </div>
            </div>
            <div class="new_list">
                <div class="new_list_item" v-for="item in articlesList[getNewsIndex]?.datalist" :key="item.article_id" @click="toNewsDetail(item.article_id)">
                    <img v-lazy="item.cover_image" />
                    <div class="item_f">
                        <span class="title">{{ item.title }}</span>
                        <span class="line"></span>
                        <span class="introduce">{{ item.summary }}</span>
                    </div>
                </div>
            </div>
            <div class="loadmore btn" @click="loadMore">查看更多</div>
        </div>
        <mobile-Loading v-if="isLoading"/>
    </div>
</template>
<script>
import swiper from '../components/swiper'
import { getIndex } from "@/api/index.js";
export default {
    name: 'index',
    components: {
        swiper
    },
    data() {
        return {
            logoList: [],
            barndList: [],
            current: 0,
            translateX: 0,
            logCurrent: 0,
            slideCurrent: 0,
            //房源类型
            houseTypeList: [],
            //新闻咨询
            articlesList: [],
            isLoading: true
        }
    },
    mounted() {
        this.initData()
    },
    methods: {
        async initData() {
            try {
                const res = await getIndex();
                //轮播图
                this.$refs.swiper.bannerList = res.data.banners;
                this.$refs.swiper.onChangeSwiper();
                //品牌
                this.addActive(res.data, "brands");
                this.logoList = res.data.brands.map((item) => {
                    return {
                        icon: item.logo,
                        active: item.active,
                    };
                });
                this.barndList = res.data.brands;
                //房型
                this.addActive(res.data, "house_types");
                res.data.house_types.forEach((item) => {
                    item.datalist = item.datalist.slice(0, 5);
                });
                this.houseTypeList = res.data.house_types;
                //新闻咨询
                this.addActive(res.data, "articles");
                this.articlesList = res.data.articles;
            }finally {
                this.isLoading = false
            }
        },
        addActive(res, list) {
            res[list].forEach((item, index) => {
                if (!index) {
                item["active"] = true;
                } else {
                item["active"] = false;
                }
            });
        },
        changeLogo(i) {
            this.logCurrent = i
            this.logoList.forEach((item, index) => {
                if(i == index) {
                    item.active = true
                }else {
                    item.active = false
                }
            });
        },
        changeSlide(type) {
            let border = '0'
            if(this.$refs.logoList) {
                border = window.getComputedStyle(this.$refs.logoList).columnGap.split('px')[0]
            }
            let width = this.$refs.listItem[0]?.offsetWidth + Number(border);
            if (type == "left") {
                if(this.logCurrent > 0) {
                    this.changeLogo(this.logCurrent - 1)
                }
                if (!this.slideCurrent) return;
                this.translateX = this.translateX + width;
                this.slideCurrent = this.slideCurrent - 1;
            } else if (type == "right") {
                if(this.logCurrent < this.logoList.length - 1) {
                    this.changeLogo(this.logCurrent + 1)
                }
                if (this.logoList.length - this.slideCurrent <= 4) return;
                this.translateX = this.translateX - width;
                this.slideCurrent = this.slideCurrent + 1;
            }
        },
        changTabs(i, type) {
            this[type].forEach((item, index) => {
                if (i == index) {
                    item.active = true;
                } else {
                    item.active = false;
                }
            });
        },
        toHouseDetail(id) {
            this.$router.push(`/houseDetail?id=${id}`)
        },
        toNewsDetail(id) {
            this.$router.push(`/newsDetail?id=${id}`)
            window.localStorage.setItem('routeTitle','新闻详情')
        },
        loadMore() {
            this.$router.push(`/news`)
            window.localStorage.setItem('routeTitle','新闻资讯')
        }
    },
    computed: {
        handleArea() {
            return (list) => {
                return list[list.length - 1];
            };
        },
        getHouseTypeIndex() {
            return this.houseTypeList.findIndex((item) => {
                return item.active;
            });
        },
        getNewsIndex() {
            return this.articlesList.findIndex((item) => {
                return item.active;
            });
        },
    }
}
</script>
<style scoped lang="scss">
.navtitle {
    .title {
        color: #FFA71D;
        font-size: 36px;
        font-weight: bold;
        width: 100%;
        text-align: center;
    }
    .horizontal_line {
        width: 150px;
        height: 2px;
        background-color: #EEEEEE;
        position: relative;
        margin: 24px auto;
        &::after {
            position: absolute;
            content: '';
            width: 40px;
            height: 4px;
            background-color: #FFA71D;
            left: 50%;
            top: -1px;
            transform: translateX(-50%);
        }
    }
}
.subtitle {
    font-size: 32px;
    color: #333333;
    font-weight: bold;
    margin: 30px 0;
    padding-left: 20px;
    box-sizing: border-box;
    position: relative;
    &::before {
        content: '';
        width: 6px;
        height: 32px;
        background: #FFA71D;
        position: absolute;
        top: 50%;
        left: 0px;
        transform: translateY(-50%);
    }
}
.container {
    .community {
        padding: 50px 30px;
        box-sizing: border-box;
        .logo_out {
            display: flex;
            column-gap: 22px;
            align-items: center;
            justify-content: space-between;
            margin: 30px 0;
            .logo_list {
                display: flex;
                column-gap: 20px;
                flex: 1;
                overflow: hidden;
                .list_item {
                    width: 122px;
                    height: 122px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-shrink: 0;
                    transition: all 0.3s ease;
                    img {
                        border-radius: 50%;
                        width: 110px;
                        height: 110px;
                        object-fit: cover;
                    }
                }
                .active {
                    border: 1px solid #FFA71D;
                    box-sizing: border-box;
                }
            }
            .left_btn,.right_btn {
                width: 48px;
                height: 48px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
        .brand_list {
            font-size: 28px;
            color: #666666;
            .bigimg {
                width: 100%;
                height: 360px;
                object-fit: cover;
            }
            p {
                text-indent: 2em;
            }
            .area {
                margin: 35px 0;
                display: flex;
                column-gap: 28px;
                color: #333333;
                font-size: 32px;
                flex-wrap: wrap;
                row-gap: 20px;
                span {
                    flex-shrink: 0;
                }
            }
            .brand_footer {
                margin-top: 40px;
                display: flex;
                flex-direction: column;
                row-gap: 20px;
                &_item {
                    width: 100%;
                    height: 120px;
                    background: url('../../../assets/image/tb_bg.png') 100% 100% no-repeat;
                    background-position: left;
                    background-size: cover;
                    display: flex;
                    align-items: center;
                    column-gap: 45px;
                    font-size: 28px;
                    color: #666666;
                    img {
                        width: 60px;
                        height: 60px;
                        margin-left: 15px;
                    }
                    .item_right {
                        display: flex;
                        column-gap: 20px;
                        align-items: center;
                        .count {
                            font-size: 40px;
                            color: #FFA71D;
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }
    .life {
        padding: 50px 30px;
        box-sizing: border-box;
        background: #F5F5F5;
        .btn_list {
            padding: 0 60px;
            box-sizing: border-box;
            display: flex;
            column-gap: 30px;
            font-size: 24px;
            color: #999999;
            flex-wrap: wrap;
            row-gap: 30px;
            justify-content: center;
            margin: 20px;
            .btn {
                flex-shrink: 0;
                width: 150px;
                height: 50px;
                border-radius: 4px;
                background: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .active {
                color: #fff;
                background: #FFA71D;
            }
        }
        .room_list {
            display: flex;
            column-gap: 18px;
            flex-wrap: wrap;
            row-gap: 20px;
            &_item:nth-child(1) {
                width: 100%;
                img {
                    height: 400px;
                    object-fit: cover;
                }
            }
            &_item {
                width: 336px;
                height: auto;
                background: #fff;
                border-radius: 6px;
                overflow: hidden;
                display: flex;
                flex-direction: column;
                img {
                    width: 100%;
                    height: 238px;
                    object-fit: cover;
                }
                .item_f {
                    width: 100%;
                    padding: 24px 20px;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                    row-gap: 20px;
                    .model {
                        color: #FFA71D;
                        font-size: 28px;
                        font-weight: bold;
                    }
                    .introduce {
                        font-size: 22px;
                        color: #999999;
                    }
                }
            }
        }
    }
    .for_us {
        width: 100%;
        height: 430px;
        background: url('../../../assets/image/pxzwm2.png') 100% 100% no-repeat;
        background-position: center;
        background-size: cover;
        padding: 50px 30px;
        box-sizing: border-box;
        .us_list {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            column-gap: 70px;
            row-gap: 20px;
            font-size: 24px;
            color: #666666;
            margin: 40px 0;
            &_item {
                border-radius: 6px;
                display: flex;
                flex-direction: column;
                row-gap: 18px;
                flex-shrink: 0;
                align-items: center;
                color: #fff;
                text-align: center;
                img {
                    width: 112px;
                    height: 112px;
                    object-fit: cover;
                }
            }
        }
    }
    .news {
        padding: 50px 30px;
        box-sizing: border-box;
        .btn_list {
            padding: 0 60px;
            box-sizing: border-box;
            display: flex;
            column-gap: 30px;
            font-size: 24px;
            color: #999999;
            flex-wrap: wrap;
            row-gap: 30px;
            justify-content: center;
            margin: 20px;
            .btn {
                flex-shrink: 0;
                width: 150px;
                height: 50px;
                border-radius: 4px;
                border: 1px solid #EEEEEE;
                background: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .active {
                color: #fff;
                background: #FFA71D;
                border: 1px solid #FFA71D;
            }
        }
        .new_list {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 18px;
            row-gap: 20px;
            &_item {
                width: 336px;
                height: auto;
                border: 1px solid #EEEEEE;
                border-radius: 6px;
                overflow: hidden;
                margin: 20px 0 40px 0;
                img {
                    width: 100%;
                    height: 240px;
                    object-fit: cover;
                    border-top-left-radius: 6px;
                    border-top-right-radius: 6px;
                }
                .item_f {
                    padding: 22px 16px;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                    row-gap: 20px;
                    .title {
                        color: #333333;
                        font-size: 28px;
                        white-space: nowrap; /* 禁止文本换行 */
                        overflow: hidden; /* 隐藏超出显示范围的内容 */
                        text-overflow: ellipsis; /* 显示省略号 */
                    }
                    .line {
                        width: 30px;
                        height: 4px;
                        background: #FFA71D;
                    }
                    .introduce {
                        font-size: 22px;
                        color: #999999;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
        .loadmore {
            color: #666666;
            font-size: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            border: 1px solid #EEEEEE;
            width: 160px;
            height: 50px;
            margin: 0 auto;
        }
    }
}
</style>